import { create } from "zustand";
import { httpApi } from "@/http-api";
import { toast } from "@/components/ui/use-toast";
import { PaymentMethod } from "./PaymentMethod";
import { DeviceBilling } from "./schemas/device-billing";
import { SubscriptionBilling } from "./schemas/subscription-billing";
import { InvoiceBilling } from "./schemas/invoice-billing";

interface BillingState {
  deviceBillings: DeviceBilling[];
  paymentMethods: PaymentMethod[];
  isLoading: boolean;
  error: string | null;
  currentPlans: {
    [deviceId: string]: {
      name: string;
      renewalDate: Date | null;
    };
  };
}

interface BillingActions {
  loadDeviceBillings: () => Promise<void>;
  downloadInvoice: (deviceId: string, subscriptionId: string, invoiceId: string) => Promise<void>;
  cancelSubscription: (deviceId: string, subscriptionId: string) => Promise<void>;
  updatePaymentMethod: (paymentMethodId: string) => Promise<void>;
  loadPaymentMethods: () => Promise<void>;
  removePaymentMethod: (paymentMethodId: string) => Promise<void>;
  setDefaultPaymentMethod: (paymentMethodId: string) => Promise<void>;
  addPaymentMethod: (paymentMethodDetails: { token: string; isDefault?: boolean }) => Promise<void>;
}

export const useBillingStore = create<BillingState & BillingActions>((set, get) => ({
  deviceBillings: [],
  paymentMethods: [],
  isLoading: false,
  error: null,
  currentPlans: {},

  loadDeviceBillings: async () => {
    try {
      set({ isLoading: true, error: null });
      const { data: deviceBillings } = await httpApi.getInvoices();
      console.log("Device Billings:", deviceBillings);

      const currentPlans: BillingState["currentPlans"] = {};

      deviceBillings.forEach((deviceBilling) => {
        const currentSubscription = deviceBilling.subcriptions.find((sub) => sub.current);
        if (currentSubscription && deviceBilling.device) {
          currentPlans[deviceBilling.device.deviceId] = {
            name: currentSubscription.subscription?.metadata?.planName || "Unknown Plan",
            renewalDate: currentSubscription.subscription?.cancelAt
              ? new Date(currentSubscription.subscription.cancelAt * 1000)
              : null,
          };
        }
      });

      set({
        deviceBillings,
        currentPlans,
        isLoading: false,
      });
    } catch (error) {
      console.error("Failed to load device billings:", error);
      set({
        error: "Failed to load billing history",
        isLoading: false,
      });
    }
  },

  loadPaymentMethods: async () => {
    try {
      set({ isLoading: true, error: null });
      const { data: paymentMethods } = await httpApi.getPaymentMethods();
      console.log("Payment Methods:", paymentMethods);

      set({
        paymentMethods,
        isLoading: false,
      });
    } catch (error) {
      console.error("Failed to load payment methods:", error);
      set({
        error: "Failed to load payment methods",
        isLoading: false,
      });
    }
  },

  removePaymentMethod: async (paymentMethodId: string) => {
    try {
      set({ isLoading: true, error: null });
      await httpApi.removePaymentMethod(paymentMethodId);
      const updatedPaymentMethods = get().paymentMethods.filter((method) => method.id !== paymentMethodId);
      set({
        paymentMethods: updatedPaymentMethods,
        isLoading: false,
      });
      toast({
        title: "Payment Method Removed",
        description: "The payment method has been successfully removed.",
      });
    } catch (error) {
      console.error("Failed to remove payment method:", error);
      set({
        error: "Failed to remove payment method",
        isLoading: false,
      });
      toast({
        title: "Error",
        description: "Failed to remove payment method. Please try again.",
        variant: "destructive",
      });
    }
  },

  setDefaultPaymentMethod: async (paymentMethodId: string) => {
    try {
      set({ isLoading: true, error: null });
      await httpApi.setDefaultPaymentMethod(paymentMethodId);
      const updatedPaymentMethods = get().paymentMethods.map((method) => ({
        ...method,
        isDefault: method.id === paymentMethodId,
      }));
      set({
        paymentMethods: updatedPaymentMethods,
        isLoading: false,
      });
      toast({
        title: "Default Payment Method Updated",
        description: "Your default payment method has been updated successfully.",
      });
    } catch (error) {
      console.error("Failed to set default payment method:", error);
      set({
        error: "Failed to set default payment method",
        isLoading: false,
      });
      toast({
        title: "Error",
        description: "Failed to set default payment method. Please try again.",
        variant: "destructive",
      });
    }
  },

  downloadInvoice: async (deviceId: string, subscriptionId: string, invoiceId: string) => {
    try {
      const deviceBilling = get().deviceBillings.find((db) => db.device?.deviceId === deviceId);
      const subscription = deviceBilling?.subcriptions.find((sub) => sub.subscription?.id === subscriptionId);
      const invoice = subscription?.invoices.find((inv) => inv.invoice?.id === invoiceId);

      if (invoice?.invoice?.invoicePdf) {
        window.open(invoice.invoice.invoicePdf, "_blank");
      } else {
        toast({
          title: "Invoice Not Found",
          description: "Unable to locate the invoice for download.",
          variant: "destructive",
        });
      }
    } catch (error) {
      console.error("Failed to download invoice:", error);
      set({ error: "Failed to download invoice" });
      toast({
        title: "Error",
        description: "Failed to download invoice. Please try again.",
        variant: "destructive",
      });
    }
  },

  cancelSubscription: async (deviceId: string, subscriptionId: string) => {
    try {
      set({ isLoading: true, error: null });
      // TODO: Implement when API endpoint is available
      // await httpApi.cancelSubscription(deviceId, subscriptionId);
      set({ isLoading: false });
      toast({
        title: "Subscription Cancelled",
        description: "Your subscription has been successfully cancelled.",
      });
    } catch (error) {
      console.error("Failed to cancel subscription:", error);
      set({
        error: "Failed to cancel subscription",
        isLoading: false,
      });
      toast({
        title: "Error",
        description: "Failed to cancel subscription. Please try again.",
        variant: "destructive",
      });
    }
  },

  updatePaymentMethod: async (paymentMethodId: string) => {
    try {
      set({ isLoading: true, error: null });
      // TODO: Implement when API endpoint is available
      // await httpApi.updatePaymentMethod(paymentMethodId);
      set({ isLoading: false });
    } catch (error) {
      console.error("Failed to update payment method:", error);
      set({
        error: "Failed to update payment method",
        isLoading: false,
      });
    }
  },

  addPaymentMethod: async (paymentMethodDetails) => {
    try {
      set({ isLoading: true, error: null });
      const { data: newPaymentMethod } = await httpApi.addPaymentMethod(paymentMethodDetails);
      const updatedPaymentMethods = [...get().paymentMethods, newPaymentMethod];
      set({
        paymentMethods: updatedPaymentMethods,
        isLoading: false,
      });
      toast({
        title: "Payment Method Added",
        description: "Your new payment method has been successfully added.",
      });
    } catch (error) {
      console.error("Failed to add payment method:", error);
      set({
        error: "Failed to add payment method",
        isLoading: false,
      });
      toast({
        title: "Error",
        description: "Failed to add payment method. Please try again.",
        variant: "destructive",
      });
    }
  },
}));

// Helper hooks for common billing operations
export const useDeviceBillings = () => useBillingStore((state) => state.deviceBillings);
export const usePaymentMethods = () => useBillingStore((state) => state.paymentMethods);
export const useBillingLoading = () => useBillingStore((state) => state.isLoading);
export const useBillingError = () => useBillingStore((state) => state.error);
export const useCurrentPlans = () => useBillingStore((state) => state.currentPlans);
