// hooks/useChartColors.ts
import { useMemo } from "react";

export const useChartColors = () => {
  return useMemo(
    () => ({
      purple: "#a855f7",
      green: "#16823e",
      red: "#ef4444",
      blue: "#3b82f6",
      orange: "#f59e0b",
      lightBlue: "#0ea5e9",
      pink: "#ec4899",
    }),
    []
  );
};
