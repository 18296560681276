import { Navigate } from "react-router-dom";
import { useAuthStore } from "./features/auth/auth-store";
import { Skeleton } from "./components/ui/skeleton";

export const PrivateRoute = ({ children }) => {
  const { user, initialized } = useAuthStore((state) => state);
  console.log("PrivateRoute user:", user, "initialized:", initialized);

  if (!initialized) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col">
        {/* Full-width header */}
        <header className="w-full bg-white shadow-sm">
          <div className="mx-auto px-4 sm:px-6 lg:px-8 py-4">
            <div className="flex items-center justify-between">
              <Skeleton className="h-8 w-32" />
              <div className="flex space-x-4">
                <Skeleton className="h-8 w-8 rounded-full" />
                <Skeleton className="h-8 w-24" />
              </div>
            </div>
          </div>
        </header>

        {/* Centered main content */}
        <main className="flex-grow flex justify-center">
          <div className="w-full max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
            <div className="space-y-8">
              {/* Main area */}
              <div className="space-y-6">
                {/* Welcome banner */}
                <Skeleton className="h-32 w-full rounded-lg" />

                {/* Stats */}
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                  {[...Array(3)].map((_, i) => (
                    <Skeleton key={i} className="h-24 rounded-lg" />
                  ))}
                </div>

                {/* Recent activity */}
                <div className="space-y-4">
                  <Skeleton className="h-8 w-1/4" />
                  {[...Array(5)].map((_, i) => (
                    <Skeleton key={i} className="h-16 w-full" />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  if (user) {
    return children;
  }

  const currentPath = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);
  const signInUrl = new URL("/auth/signin", window.location.origin);
  signInUrl.searchParams.append("redirectTo", currentPath);
  signInUrl.searchParams.append("redirectParams", searchParams.toString());
  window.location.href = signInUrl.toString();
};
