import React, { useEffect, useRef, useState } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from "recharts";
import { format } from "date-fns";
import { merge, Subject, Subscription } from "rxjs";
import { ChartTooltip } from "@/components/ui/chart";
import { useChartColors } from "@/hooks/useChartColors";
import { useDashboardStore } from "./dashboard-store";
import { useUnitConversion } from "@/hooks/useUnitConversion";

const MAX_DATA_POINTS = 3000;

type Reading = Record<string, { time: number; value: number }[]>;

const formatXAxis = (unixTime) => {
  return new Date(unixTime).toLocaleTimeString();
};

const CustomTooltip = (metric: "pressure" | "temperature" | "current") => {
  const { convertValue, getUnit } = useUnitConversion();

  return (props: { active: boolean; payload: any[]; label: number }) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      return (
        <div className="bg-white dark:bg-slate-800 p-2 border border-slate-200 dark:border-slate-700 rounded-lg shadow-lg opacity-100 z-20">
          <p className="text-sm font-medium">{format(new Date(label), "MMM dd, yyyy HH:mm:ss")}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }} className="text-sm">
              {`${entry.name}: ${convertValue(entry.value, metric).toFixed(0)} ${getUnit(metric)}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };
};

const CustomLegend = (metric: string, units?: { imperial: string; metric: string }) => {
  const { ui } = useDashboardStore.getState();
  const currentUnits = units ? (ui.showMetric ? units.metric : units.imperial) : "";

  return (props) => {
    const { payload } = props;

    return (
      <div className="hidden md:flex flex-col gap-3 p-4 md:w-48">
        <span className="hidden md:inline">{`${metric}${currentUnits ? ` (${currentUnits})` : ""}`}</span>
        {payload?.map((entry, index) => (
          <div
            key={`legend-item-${index}`}
            className="flex items-center cursor-pointer"
            onClick={() => entry.payload.onClick?.()}
          >
            <div className="relative w-8 h-4 mr-2 flex items-center">
              <div className="absolute w-8 h-4" style={{ backgroundColor: entry.color, opacity: 0.3 }} />
              <div className="absolute w-8 h-[2px]" style={{ backgroundColor: entry.color }} />
            </div>
            <span className="text-sm truncate">{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };
};

const LiveValues = ({ values }) => {
  const { convertValue, getUnit } = useUnitConversion();

  return (
    <div className="absolute top-0 right-2 flex flex-col justify-start pointer-events-none z-0" style={{ height: 150 }}>
      <div>
        {values.map(
          ({ value, color, type }) =>
            value && (
              <div key={color} className="text-center">
                <span className="text-4xl tracking-wide font-bold opacity-90 " style={{ color }}>
                  {convertValue(value, type).toFixed(0)}
                </span>
                <span className="text-xs opacity-90 ml-1" style={{ color }}>
                  {getUnit(type)}
                </span>
              </div>
            )
        )}
      </div>
    </div>
  );
};

const SynchronizedCharts = ({ data }) => {
  const { ui } = useDashboardStore.getState();
  const [syncId] = useState("hvacSync");
  const [timeWindow, setTimeWindow] = useState({
    startTime: data.length > 0 ? data[data.length - 100]?.time : null,
    endTime: data.length > 0 ? data[data.length - 1]?.time : null,
  });
  const filteredData = data.filter((point) => {
    if (timeWindow.startTime === null && timeWindow.endTime === null) return true;
    if (timeWindow.startTime === null) return point.time <= timeWindow.endTime;
    if (timeWindow.endTime === null) return point.time >= timeWindow.startTime;
    return point.time >= timeWindow.startTime && point.time <= timeWindow.endTime;
  });

  const latestData = data[data.length - 1] || {};

  const startIndex = data.findIndex((point) => point.time === timeWindow.startTime);
  const endIndex = data.findIndex((point) => point.time === timeWindow.endTime);
  const chartColors = useChartColors();

  return (
    <div className="space-y-1">
      {["pressure", "temperature", "current"].map((chartType, index) => (
        <div key={chartType} className="h-48 relative mb-8 md:mb-0">
          <ResponsiveContainer width="100%" height={150}>
            <AreaChart data={filteredData} syncId={syncId} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <defs>
                {/* Define gradients for each area */}
                <linearGradient id="gradientPressureHigh" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={chartColors.blue} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={chartColors.blue} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="gradientPressureLow" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={chartColors.green} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={chartColors.green} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="gradientTemperatureHigh" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={chartColors.red} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={chartColors.red} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="gradientTemperatureLow" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={chartColors.blue} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={chartColors.blue} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="gradientCurrent1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={chartColors.orange} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={chartColors.orange} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="gradientCurrent2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={chartColors.lightBlue} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={chartColors.lightBlue} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="gradientCurrent3" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={chartColors.pink} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={chartColors.pink} stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis hide={true} dataKey="time" tickFormatter={formatXAxis} minTickGap={50} interval="preserveEnd" />
              <YAxis hide={true} domain={["dataMin - 5", "dataMax + 5"]} tick={false} />
              <Tooltip
                content={CustomTooltip(
                  chartType === "pressure" ? "pressure" : chartType === "temperature" ? "temperature" : "current"
                )}
                wrapperStyle={{ zIndex: 50 }}
              />

              <Legend
                layout="vertical"
                verticalAlign="middle"
                align="left"
                content={CustomLegend(
                  chartType === "pressure" ? "Pressure" : chartType === "temperature" ? "Temperature" : "Current",
                  chartType === "pressure"
                    ? { imperial: "PSI", metric: "kPa" }
                    : chartType === "temperature"
                    ? { imperial: "°F", metric: "°C" }
                    : { imperial: "A", metric: "A" }
                )}
                wrapperStyle={{
                  position: "absolute",
                  left: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  visibility: window.innerWidth >= 768 ? "visible" : "hidden", // Hide on mobile
                }}
              />

              {chartType === "pressure" && (
                <>
                  <Area
                    type="monotone"
                    dataKey="pressureHigh"
                    stroke={chartColors.blue}
                    fill="url(#gradientPressureHigh)"
                    fillOpacity={0.5}
                    strokeWidth={1}
                    name="High Pressure"
                    isAnimationActive={false}
                    dot={false}
                  />
                  <Area
                    type="monotone"
                    dataKey="pressureLow"
                    stroke={chartColors.green}
                    fill="url(#gradientPressureLow)"
                    fillOpacity={0.5}
                    strokeWidth={1}
                    name="Low Pressure"
                    isAnimationActive={false}
                    dot={false}
                  />
                </>
              )}
              {chartType === "temperature" && (
                <>
                  <Area
                    type="monotone"
                    dataKey="temperatureHigh"
                    stroke={chartColors.red}
                    fill="url(#gradientTemperatureHigh)"
                    fillOpacity={0.5}
                    strokeWidth={1}
                    name="High Temperature"
                    isAnimationActive={false}
                    dot={false}
                  />
                  <Area
                    type="monotone"
                    dataKey="temperatureLow"
                    stroke={chartColors.blue}
                    fill="url(#gradientTemperatureLow)"
                    fillOpacity={0.5}
                    strokeWidth={1}
                    name="Low Temperature"
                    isAnimationActive={false}
                    dot={false}
                  />
                </>
              )}
              {chartType === "current" && (
                <>
                  <Area
                    type="monotone"
                    dataKey="current1"
                    stroke={chartColors.orange}
                    fill="url(#gradientCurrent1)"
                    fillOpacity={0.6}
                    strokeWidth={1}
                    name="Compressor"
                    isAnimationActive={false}
                    dot={false}
                  />
                  <Area
                    type="monotone"
                    dataKey="current2"
                    stroke={chartColors.lightBlue}
                    fill="url(#gradientCurrent2)"
                    fillOpacity={0.6}
                    strokeWidth={1}
                    name="Fan"
                    isAnimationActive={false}
                    dot={false}
                  />
                  <Area
                    type="monotone"
                    dataKey="current3"
                    stroke={chartColors.pink}
                    fill="url(#gradientCurrent3)"
                    fillOpacity={0.6}
                    strokeWidth={1}
                    name="Blower Fan"
                    isAnimationActive={false}
                    dot={false}
                  />
                </>
              )}
            </AreaChart>
          </ResponsiveContainer>
          <LiveValues
            values={
              chartType === "pressure"
                ? [
                    {
                      value: latestData.pressureLow ?? null,
                      color: chartColors.blue,
                      type: "pressure",
                    },
                    {
                      value: latestData.pressureHigh ?? null,
                      color: chartColors.green,
                      type: "pressure",
                    },
                  ]
                : chartType === "temperature"
                ? [
                    {
                      value: latestData.temperatureHigh ?? null,
                      color: chartColors.red,
                      type: "temperature",
                    },
                    {
                      value: latestData.temperatureLow ?? null,
                      color: chartColors.blue,
                      type: "temperature",
                    },
                  ]
                : [
                    {
                      value: latestData.current1 ?? null,
                      color: chartColors.orange,
                      type: "current",
                    },
                    {
                      value: latestData.current2 ?? null,
                      color: chartColors.lightBlue,
                      type: "current",
                    },
                    {
                      value: latestData.current3 ?? null,
                      color: chartColors.pink,
                      type: "current",
                    },
                  ]
            }
          />
        </div>
      ))}
      <div className="h-16">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data} syncId={syncId} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
            <XAxis dataKey="time" tickFormatter={formatXAxis} minTickGap={50} />
            <Brush
              dataKey="time"
              height={30}
              stroke="#8884d8"
              tickFormatter={formatXAxis}
              startIndex={startIndex !== -1 ? startIndex : undefined}
              endIndex={endIndex !== -1 ? endIndex : undefined}
              onChange={(e) => {
                if (e.startIndex === undefined || e.endIndex === undefined) return;
                const isAtStart = e.startIndex === 0;
                const isAtEnd = e.endIndex === data.length - 1;
                setTimeWindow({
                  startTime: isAtStart ? null : data[e.startIndex]?.time,
                  endTime: isAtEnd ? null : data[e.endIndex]?.time,
                });
              }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

type RechartsStreamVisualizerProps = {
  lowLine$: Subject<Reading>;
  highLine$: Subject<Reading>;
  current$: Subject<Reading>;
};

export const RechartsStreamVisualizer = ({ lowLine$, highLine$, current$ }: RechartsStreamVisualizerProps) => {
  const dataRef = useRef([]);
  const subscriptionRef = useRef<Subscription | null>(null);
  const chartColors = useChartColors();

  const determineOperatingMode = (data) => {
    const latestData = data[data.length - 1];
    if (!latestData) return "Unknown";

    const { pressureHigh, pressureLow, current1 } = latestData;

    if (pressureHigh > pressureLow && current1 > 0) {
      return "Cooling";
    } else if (pressureLow > pressureHigh && current1 > 0) {
      return "Heating";
    } else {
      return "Unknown";
    }
  };

  useEffect(() => {
    if (!lowLine$ || !highLine$ || !current$) {
      console.warn("Missing one or more data streams");
      return;
    }

    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
    }

    dataRef.current = [];

    subscriptionRef.current = merge(lowLine$, highLine$, current$).subscribe({
      next: (newReadings: Reading) => {
        let newData = [...dataRef.current];

        Object.entries(newReadings).forEach(([key, readings]) => {
          readings.forEach((reading) => {
            const timestamp = reading.time;
            let existingPointIndex = newData.findIndex((point) => point.time === timestamp);

            if (existingPointIndex !== -1) {
              newData[existingPointIndex] = {
                ...newData[existingPointIndex],
                [key]: reading.value,
              };
            } else {
              newData.push({
                time: timestamp,
                [key]: reading.value,
              });
            }
          });
        });

        newData.sort((a, b) => a.time - b.time);

        if (newData.length > MAX_DATA_POINTS) {
          newData = newData.slice(-MAX_DATA_POINTS);
        }

        dataRef.current = newData;
        const newMode = determineOperatingMode(dataRef.current);
        useDashboardStore.getState().setOperatingMode(newMode);
      },
      error: (error) => {
        console.error("Error in data stream:", error);
      },
    });

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, [lowLine$, highLine$, current$]);

  return <SynchronizedCharts data={dataRef.current} />;
};
