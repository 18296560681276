import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Customer, InstalledDevice, NewCustomerSetup } from "@rtbot-dev/json-schemas";
import { PaymentMethod } from "./features/dashboard/billing/PaymentMethod";
import { DeviceBilling } from "../../../../services/cool-guardian/src/models/billing/device-billing";

export type StripeInvoice = {
  id?: string;
  object: "invoice";
  accountName: string;
  createdAt: number;
  webhooksDeliveredAt: number;
  customerEmail: string;
  customerPhone: string;
  amountDue: number;
  amountPaid: number;
  paid: boolean;
  paidAt: number | null;
  lines: any[];
  invoicePdf: string;
  hostedInvoiceUrl: string;
  currency: string;
  customer: string;
  metadata: any;
  paymentIntent: any | string | null;
};

class HttpApi {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: "https://api.cool-guardian.com/v1",
    });
  }

  setToken(token: string) {
    this.client.interceptors.request.use((config: any) => {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    });
  }

  private request(req: AxiosRequestConfig<any>) {
    return this.client.request(req);
  }

  getDevices() {
    return this.request({
      method: "GET",
      url: "/devices",
    }) as Promise<AxiosResponse<any>>;
  }

  partnerStartSetup(newCustomerSetup: NewCustomerSetup) {
    return this.request({
      method: "POST",
      url: "/partner/start-setup",
      data: newCustomerSetup,
    });
  }

  partnerContinueSetupWithCode(code: string) {
    return this.request({
      method: "POST",
      url: "/partner/continue-setup",
      data: { code },
    });
  }

  getPrices() {
    return this.request({
      method: "GET",
      url: "/payment/prices",
    });
  }

  getInvoices(): Promise<AxiosResponse<DeviceBilling[]>> {
    return this.request({
      method: "GET",
      url: "/payment/history",
    });
  }

  createPaymentIntent(deviceId: string, recurringStripePriceId: string, oneTimeStripePriceId: string) {
    return this.request({
      method: "POST",
      url: "/payment/subscription",
      data: {
        deviceId,
        recurringStripePriceId,
        oneTimeStripePriceId,
      },
    });
  }

  getCustomers() {
    return this.request({
      method: "GET",
      url: "/partner/customers",
    }) as Promise<AxiosResponse<Customer[]>>;
  }

  getCustomerDevices(customerId: string) {
    return this.request({
      method: "GET",
      url: `/partner/customer/${customerId}/devices`,
    }) as Promise<AxiosResponse<InstalledDevice[]>>;
  }

  renameDevice(deviceId: string, name: string): Promise<AxiosResponse<InstalledDevice>> {
    return this.request({
      method: "PATCH",
      url: `/devices/${deviceId}`,
      data: { name },
    }) as Promise<AxiosResponse<InstalledDevice>>;
  }

  getPaymentMethods(): Promise<AxiosResponse<PaymentMethod[]>> {
    return this.request({
      method: "GET",
      url: "/payment/methods",
    });
  }

  addPaymentMethod(paymentMethodDetails: {
    token: string;
    isDefault?: boolean;
  }): Promise<AxiosResponse<PaymentMethod>> {
    return this.request({
      method: "POST",
      url: "/payment/methods",
      data: paymentMethodDetails,
    });
  }

  setDefaultPaymentMethod(paymentMethodId: string): Promise<AxiosResponse<PaymentMethod>> {
    return this.request({
      method: "PATCH",
      url: `/payment/methods/${paymentMethodId}/default`,
    });
  }

  removePaymentMethod(paymentMethodId: string): Promise<AxiosResponse<void>> {
    return this.request({
      method: "DELETE",
      url: `/payment/methods/${paymentMethodId}`,
    });
  }

  async getInvoiceByPaymentIntent(paymentIntentId: string, retries = 3): Promise<AxiosResponse<DeviceBilling>> {
    try {
      return await this.request({
        method: "GET",
        url: `/payment/invoice/${paymentIntentId}`,
      });
    } catch (error) {
      if (retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
        return this.getInvoiceByPaymentIntent(paymentIntentId, retries - 1);
      }
      throw error;
    }
  }
}

export const httpApi = new HttpApi();
