import { create, StoreApi, UseBoundStore } from "zustand";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import { httpApi } from "@/http-api";

loadStripe(
  "pk_test_51PK2kWKIN72O6H31IVFRWNsxORL4PZtLCSo6uibsQyVvTDKXDur6SekMcx01bCapHFYIS4aiOfvF2KCe7q96xm3800j1HQ6cWL"
).then((stripe) => {
  console.log("Stripe loaded", stripe);
  usePaymentStore.getState().setStripe(stripe);
});

export type BundlePrice = {
  oneTime?: Price;
  recurring: Price;
};

export type Price = {
  id: string;
  unitAmount: number;
  nickname: string;
  recurring?: { deviceId; recurringPriceId; oneTimePriceId; interval: "month" | "year"; intervalCount: number };
  metadata: { description: string | undefined; features: string | undefined } | undefined;
};

interface PaymentOption {
  selectedPlan: string;
  selectedPrice: number;
  selectedPlanDescription: string;
  totalPrice: number;
  oneTimePriceId: string;
  priceOneTime: number;
  featureSelected: string[];
}

export type PaymentStoreState = {
  clientSecret?: string;
  stripe?: Stripe;
  elements?: StripeElements;
  prices?: BundlePrice[];
  selectedPrice?: BundlePrice;
  selectedPaymentOption: PaymentOption;
  latestInvoice?: any;
  invoice?: any;
  isLoading: boolean;
  error: string | null;
};

export type PaymentStoreActions = {
  getPrices: () => Promise<void>;
  createPaymentIntent: (deviceId: string, recurringPriceId: string, oneTimePriceId: string) => Promise<void>;
  initiatePayment: () => Promise<void>;
  setStripe: (stripe: Stripe) => void;
  setElements: (elements: StripeElements) => void;
  setSelectedPaymentOption: (
    plan: string,
    price: number,
    planDescription: string,
    totalPrice: number,
    oneTimePriceId: string,
    priceOneTime: number,
    features: string[]
  ) => void;
  setLatestInvoice: (invoice: any) => void;
  setLoading: (isLoading: boolean) => void;
  setError: (error: string | null) => void;
  fetchInvoice: (paymentIntentId: string) => Promise<void>;
};

export type PaymentStore = PaymentStoreState & PaymentStoreActions;

export const usePaymentStore: UseBoundStore<StoreApi<PaymentStore>> = create<PaymentStore>((set, get) => ({
  selectedPaymentOption: {
    selectedPlan: "",
    selectedPrice: 0,
    selectedPlanDescription: "",
    totalPrice: 0,
    oneTimePriceId: "",
    priceOneTime: 200,
    featureSelected: [],
  },
  setSelectedPaymentOption: (plan, price, planDescription, totalPrice, oneTimePriceId, priceOneTime, features) =>
    set({
      selectedPaymentOption: {
        selectedPlan: plan,
        selectedPrice: price,
        selectedPlanDescription: planDescription,
        totalPrice: totalPrice,
        oneTimePriceId: oneTimePriceId,
        priceOneTime: priceOneTime,
        featureSelected: features,
      },
    }),
  setStripe: (stripe) => set({ stripe }),
  setElements: (elements) => set({ elements }),
  setLatestInvoice: (invoice) => set({ latestInvoice: invoice }),
  isLoading: false,
  error: null,
  setLoading: (isLoading) => set({ isLoading }),
  setError: (error) => set({ error }),
  async getPrices() {
    if (!get().stripe) {
      throw new Error("Stripe is not set");
    }
    const { data: prices } = await httpApi.getPrices();
    console.log("Prices", prices);
    return set({ prices });
  },
  async createPaymentIntent(deviceId: string, recurringPriceId: string, oneTimePriceId: string) {
    set({ isLoading: true, error: null });
    try {
      if (!get().stripe) {
        throw new Error("Stripe is not set");
      }
      const { data } = await httpApi.createPaymentIntent(deviceId, recurringPriceId, oneTimePriceId);
      console.log("Payment intent", data);
      console.log("Client secret", data.latestInvoice.paymentIntent.clientSecret);
      set({
        clientSecret: data.latestInvoice.paymentIntent.clientSecret,
        latestInvoice: data.latestInvoice,
        isLoading: false,
      });
    } catch (error) {
      set({ error: error.message, isLoading: false });
    }
  },
  async initiatePayment() {
    console.log("Initiating payment");
    if (!get().stripe) {
      throw new Error("Stripe is not set");
    }
    if (!get().clientSecret) {
      throw new Error("Client secret is not set");
    }

    const elements = get().elements;

    const returnUrl = new URL(location.href);
    returnUrl.search = "";
    returnUrl.pathname = "/payment/success";

    const confirmPaymentResult = await get().stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl.toString(),
      },
    });

    console.log("Confirm payment result", confirmPaymentResult);

    if (confirmPaymentResult.error) {
      console.error(confirmPaymentResult.error.message);
    }
  },
  fetchInvoice: (paymentIntentId: string) => {
    set({ isLoading: true, error: null });
    return httpApi
      .getInvoiceByPaymentIntent(paymentIntentId)
      .then(({ data }) => {
        set({ invoice: data, isLoading: false });
      })
      .catch((error) => {
        set({ error: "Failed to fetch invoice. Please try again.", isLoading: false });
      });
  },
}));
