import { EChartsOption } from "echarts";
import { getPsiToF, Refrigerant } from "@/utils/refrigerants";

export const lowLineOptions: EChartsOption = {
  series: [
    {
      title: "Low Line",
      radius: "90%",
      type: "gauge",
      center: ["50%", "60%"],
      startAngle: 220,
      endAngle: -40,
      min: 0,
      max: 500,
      z: 200,
      splitNumber: 10,
      progress: {
        show: true,
        overlap: false,
        roundCap: false,
      },
      pointer: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          width: 35,
        },
      },
      axisTick: {
        distance: -45,
        splitNumber: 5,
        lineStyle: {
          width: 2,
          color: "#999",
        },
      },
      splitLine: {
        distance: -52,
        length: 8,
        lineStyle: {
          width: 2,
          color: "#999",
        },
      },
      axisLabel: {
        distance: 7,
        color: "#999",
        fontSize: 10,
      },
      detail: {
        show: false,
      },
      data: [
        {
          sensorId: "pressureLow",
          value: 0,
          unit: "PSI",
          progress: {
            show: true,
            width: 20,
          },
          detail: {
            valueAnimation: true,
          },
          pointer: {
            show: true,
          },
          itemStyle: {
            color: "hsl(191.5,100%,51.8%)",
            shadowColor: "#FD7349",
          },
        },
        {
          sensorId: "temperatureLow",
          value: 0,
          unit: "℉",
          pointer: {
            show: false,
          },
          detail: {
            valueAnimation: true,
          },
          itemStyle: {
            color: "hsl(191.9,100%,84.1%)",
            shadowColor: "#FD7349",
          },
        },
      ],
    },
  ],
};

export const highLineOptions: EChartsOption = {
  series: [
    {
      title: "High Line",
      min: 0,
      max: 500,
      radius: "90%",
      center: ["50%", "60%"],
      startAngle: 220,
      endAngle: -40,
      type: "gauge",
      splitNumber: 10,
      progress: {
        show: true,
        overlap: false,
        roundCap: false,
      },
      pointer: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          width: 35,
        },
      },
      axisTick: {
        distance: -45,
        splitNumber: 5,
        lineStyle: {
          width: 2,
          color: "#999",
        },
      },
      splitLine: {
        distance: -52,
        length: 8,
        lineStyle: {
          width: 2,
          color: "#999",
        },
      },
      axisLabel: {
        distance: 7,
        color: "#999",
        fontSize: 10,
      },
      detail: {
        show: false,
      },
      data: [
        {
          sensorId: "pressureHigh",
          value: 0,
          unit: "PSI",
          progress: {
            show: true,
            width: 20,
          },
          detail: {
            valueAnimation: true,
          },
          pointer: {
            show: true,
          },
          itemStyle: {
            color: "hsl(14.2,100%,78.4%)",
            shadowColor: "#FD7349",
          },
        },
        {
          sensorId: "temperatureHigh",
          value: 0,
          unit: "℉",
          detail: {
            valueAnimation: true,
          },
          progress: {
            show: true,
            width: 8,
          },
          itemStyle: {
            color: "hsl(14.5,97.8%,63.5%)",
            shadowColor: "#FD7349",
          },
        },
      ],
    },
  ],
};

export const currentOptions: EChartsOption = {
  series: [
    {
      title: "Current",
      startAngle: 220,
      endAngle: -40,
      radius: "90%",
      center: ["50%", "60%"],
      type: "gauge",
      splitNumber: 5,
      min: 0,
      max: 50,
      progress: {
        show: true,
        overlap: false,
        roundCap: false,
      },
      pointer: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          width: 35,
        },
      },
      axisTick: {
        distance: -45,
        splitNumber: 5,
        lineStyle: {
          width: 2,
          color: "#999",
        },
      },
      splitLine: {
        distance: -52,
        length: 8,
        lineStyle: {
          width: 2,
          color: "#999",
        },
      },
      axisLabel: {
        distance: 7,
        color: "#999",
        fontSize: 10,
      },
      detail: {
        show: false,
      },
      data: [
        {
          sensorId: "current1",
          name: "Compressor",
          unit: "A",
          value: 0,
          itemStyle: {
            color: "hsl(191.4,100%,49.4%)",
            shadowColor: "#FD7349",
          },
        },
        {
          sensorId: "current2",
          value: 0,
          name: "Fan",
          unit: "A",
          itemStyle: {
            color: "#0096ba",
            shadowColor: "#FD7349",
          },
        },
      ],
    },
  ],
};

export const historicalChartOptions: EChartsOption = {
  animation: false,
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
    },
    formatter: (params: any) => {
      let html = `${new Date(params[0].value[0]).toLocaleString()}<br/>`;
      html += "<br />";

      // Helper function to add a data point to the tooltip
      const addDataPoint = (param: any, label: string, unit: string) => {
        if (param) {
          html += `<div style="background-color: ${
            param.color
          }; height: 8px; width: 8px; border-radius: 50%; display: inline-block;"> </div> ${label}: <b>${Math.round(
            param.value[1]
          )} ${unit}</b><br/>`;
        }
      };

      // Add temperature and pressure data
      const tempLow = params.find((p: any) => p.seriesName === "temperatureLow");
      const tempHigh = params.find((p: any) => p.seriesName === "temperatureHigh");
      const pressLow = params.find((p: any) => p.seriesName === "pressureLow");
      const pressHigh = params.find((p: any) => p.seriesName === "pressureHigh");

      if (tempLow && pressLow) {
        html += "<b>Low Line</b><br/>";
        addDataPoint(pressLow, "Pressure", "PSI");
        addDataPoint(tempLow, "Temperature", "°F");
        html += "<br/>";
      }

      if (tempHigh && pressHigh) {
        html += "<b>High Line</b><br/>";
        addDataPoint(pressHigh, "Pressure", "PSI");
        addDataPoint(tempHigh, "Temperature", "°F");
        html += "<br/>";
      }

      // Add current data
      const current1 = params.find((p: any) => p.seriesName === "current1");
      const current2 = params.find((p: any) => p.seriesName === "current2");

      if (current1 || current2) {
        html += "<b>Current</b><br/>";
        addDataPoint(current1, "Compressor", "A");
        addDataPoint(current2, "Fan", "A");
      }

      return html;
    },
  },
  xAxis: [
    {
      min: "dataMin",
      max: "dataMax",
      type: "time",
      gridIndex: 0,
    },
    {
      min: "dataMin",
      max: "dataMax",
      type: "time",
      gridIndex: 1,
    },
  ],
  grid: [
    {
      height: "35%",
    },
    {
      top: "55%",
      height: "30%",
    },
  ],
  yAxis: [
    {
      type: "value",
      name: "Pressure",
      position: "right",
      alignTicks: true,
      axisLabel: {
        formatter: "{value} PSI",
      },
      gridIndex: 0,
    },
    {
      type: "value",
      name: "Temperature",
      position: "left",
      alignTicks: true,
      axisLabel: {
        formatter: "{value} °F",
      },
      gridIndex: 0,
    },
    {
      type: "value",
      name: "Current",
      position: "right",
      alignTicks: true,
      gridIndex: 1,
      axisLabel: {
        formatter: "{value} A",
      },
    },
  ],
  dataZoom: [
    {
      show: true,
      type: "slider",
      top: "90%",
      xAxisIndex: [0, 1],
    },
  ],
  series: [
    {
      name: "temperatureLow",
      color: "hsl(14.5,97.8%,63.5%)",
      data: [],
      xAxisIndex: 0,
      yAxisIndex: 1,
      type: "line",
    },
    {
      name: "temperatureHigh",
      color: "hsl(14.2,100%,78.4%)",
      data: [],
      xAxisIndex: 0,
      yAxisIndex: 1,
      type: "line",
    },
    {
      name: "pressureLow",
      color: "hsl(191.9,100%,84.1%)",
      data: [],
      type: "line",
      xAxisIndex: 0,
      yAxisIndex: 0,
    },
    {
      name: "pressureHigh",
      color: "hsl(191.5,100%,51.8%)",
      type: "line",
      data: [],
      xAxisIndex: 0,
      yAxisIndex: 0,
    },
    {
      name: "current1",
      color: "hsl(191.4,100%,49.4%)",
      data: [],
      type: "line",
      xAxisIndex: 1,
      yAxisIndex: 2,
    },
    {
      name: "current2",
      color: "#0096ba",
      data: [],
      type: "line",
      xAxisIndex: 1,
      yAxisIndex: 2,
    },
  ],
};
