import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { CreditCard, Trash2 } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useBillingStore, usePaymentMethods, useBillingLoading } from "./billing-store";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

export const PaymentMethodManager = () => {
  const paymentMethods = usePaymentMethods();
  const isLoading = useBillingLoading();
  const { removePaymentMethod, setDefaultPaymentMethod } = useBillingStore();

  const handleRemovePaymentMethod = async (id: string) => {
    try {
      await removePaymentMethod(id);
    } catch (error) {
      console.error("Error removing payment method:", error);
    }
  };

  const handleSetDefaultPaymentMethod = async (id: string) => {
    try {
      await setDefaultPaymentMethod(id);
    } catch (error) {
      console.error("Error setting default payment method:", error);
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle className="text-lg sm:text-xl font-semibold">Payment Methods</CardTitle>
          <Dialog>
            <DialogTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className="hover:bg-primary hover:text-primary-foreground transition-colors duration-200"
              >
                <CreditCard className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
                <span className="text-xs sm:text-sm">Add Card</span>
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add New Payment Method</DialogTitle>
              </DialogHeader>
              {/* Implement Stripe Elements or custom form here */}
              <p>Stripe Elements form placeholder</p>
            </DialogContent>
          </Dialog>
        </div>
      </CardHeader>
      <CardContent>
        <p className="text-xs sm:text-sm text-muted-foreground mb-4">Manage your saved payment methods</p>
        {isLoading ? (
          <div className="text-center text-muted-foreground">Loading payment methods...</div>
        ) : (
          <div className="space-y-4">
            {paymentMethods.length === 0 ? (
              <div className="text-center text-muted-foreground text-sm py-4">No payment methods saved</div>
            ) : (
              paymentMethods.map((method) => (
                <div
                  key={method.id}
                  className={`flex items-center justify-between p-3 sm:p-4 border rounded-lg transition-colors duration-200 ${
                    method.isDefault ? "bg-primary/10 border-primary/50" : "bg-muted/30 hover:bg-muted/50"
                  }`}
                >
                  <div className="flex items-center gap-2 sm:gap-4">
                    <div
                      className={`w-10 h-6 sm:w-12 sm:h-8 rounded flex items-center justify-center text-white text-[10px] sm:text-xs font-bold ${
                        method.brand === "visa"
                          ? "bg-gradient-to-r from-blue-500 to-purple-500"
                          : "bg-gradient-to-r from-red-500 to-orange-500"
                      }`}
                    >
                      {method.brand?.toUpperCase()}
                    </div>
                    <div className="flex flex-col">
                      <span className="text-xs sm:text-sm font-medium">•••• •••• •••• {method.last4}</span>
                      <span className="text-xs text-muted-foreground">
                        Expires: {method.expMonth}/{method.expYear}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleSetDefaultPaymentMethod(method.id)}
                            disabled={method.isDefault || isLoading}
                            className={`transition-colors duration-200 ${
                              method.isDefault ? "text-primary" : "hover:bg-primary/10"
                            }`}
                          >
                            {method.isDefault ? "Default" : "Set as Default"}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>
                            {method.isDefault
                              ? "This is your default payment method"
                              : "Set as your default payment method"}
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    {!method.isDefault && (
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button
                            variant="ghost"
                            size="sm"
                            className="hover:bg-destructive hover:text-destructive-foreground transition-colors duration-200"
                          >
                            <Trash2 className="h-3 w-3 sm:h-4 sm:w-4" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                            <AlertDialogDescription>
                              This action cannot be undone. This will permanently delete your payment method.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={() => handleRemovePaymentMethod(method.id)}>
                              Remove
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};
