import { useDashboardStore } from "@/features/dashboard/dashboard-store";
import convert from "convert";

export function useUnitConversion() {
  const { showMetric } = useDashboardStore((state) => ({
    showMetric: state.ui.showMetric,
  }));

  const convertValue = (value: number, type: "temperature" | "pressure" | "current") => {
    if (type === "temperature") {
      return showMetric ? Math.round(convert(value, "fahrenheit").to("celsius")) : Math.round(value);
    } else if (type === "pressure") {
      return showMetric ? Math.round(convert(value, "psi").to("kPa")) : Math.round(value);
    }
    // Current doesn't need conversion
    return Math.round(value);
  };

  const getUnit = (type: "temperature" | "pressure" | "current") => {
    if (type === "temperature") {
      return showMetric ? "°C" : "°F";
    } else if (type === "pressure") {
      return showMetric ? "kPa" : "PSI";
    }
    return "A";
  };

  return { convertValue, getUnit };
}
