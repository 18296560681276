import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Check, Download, AlertTriangle } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { usePaymentStore } from "../payment-store";
import LayoutPayment from "../layout/LayoutPayment";
import { useCallback, useEffect, useRef, useMemo } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import type { CreateTypes } from "canvas-confetti";
import { useSearchParams } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";

export default function SuccessPaymentPage() {
  const navigate = useNavigate();
  const { fetchInvoice, invoice, isLoading, error, selectedPaymentOption } = usePaymentStore();
  const [searchParams] = useSearchParams();
  const refAnimationInstance = useRef<CreateTypes | null>(null);

  const getInstance = useCallback((instance: CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio: number, opts: object) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    const paymentIntentId = searchParams.get("payment_intent");
    if (paymentIntentId) {
      fetchInvoice(paymentIntentId);
    }
  }, [fetchInvoice, searchParams]);

  const invoiceDetails = useMemo(
    () => ({
      refNumber: invoice?.id || "N/A",
      paymentTime: invoice ? new Date(invoice.createdAt * 1000).toLocaleString() : "N/A",
      paymentMethod: invoice?.paymentIntent ? "Credit Card" : "N/A",
      customerEmail: invoice?.customerEmail || "N/A",
      amount: invoice ? `$${(invoice.amountPaid / 100).toFixed(2)}` : "N/A",
      status: invoice?.paid ? "Paid" : "Pending",
    }),
    [invoice]
  );

  const handleRetry = useCallback(() => {
    const paymentIntentId = searchParams.get("payment_intent");
    if (paymentIntentId) {
      fetchInvoice(paymentIntentId);
    }
  }, [fetchInvoice, searchParams]);

  return (
    <LayoutPayment>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={{
          position: "fixed",
          pointerEvents: "none",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: 100,
        }}
      />
      <div className="flex justify-center">
        <div className="container max-w-screen-md px-4 md:px-8 items-center bg-transparent dark:bg-transparent">
          <div className="flex flex-col w-full justify-center mt-28 sm:mt-40">
            <div className="p-6 shadow-xl shadow-cyan-pale bg-cyan-vivid/10 rounded-xl border border-[1px]">
              <div className="space-y-4">
                <div className="flex justify-center">
                  <div className="relative bg-cyan-mostwhite p-2 rounded-full z-10">
                    <div className="bg-cyan-pale p-4 rounded-full">
                      <div className="bg-primary p-2 rounded-full">
                        {isLoading ? (
                          <Skeleton className="h-6 w-6 rounded-full" />
                        ) : error ? (
                          <AlertTriangle className="h-6 w-6 text-red-500" />
                        ) : (
                          <Check className="h-6 w-6 text-white" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="font-bold text-2xl sm:text-4xl text-center text-strong-cyan">
                  {isLoading ? "Processing Payment" : error ? "Payment Error" : "Payment Success"}
                </h2>
                <p className="text-lg font-semibold text-center">
                  {isLoading ? (
                    <Skeleton className="h-6 w-24 mx-auto" />
                  ) : (
                    `$${invoice ? (invoice.amountPaid / 100).toFixed(2) : selectedPaymentOption.totalPrice}`
                  )}
                </p>
                <p className="text-md text-center text-muted-foreground">
                  {isLoading
                    ? "Please wait while we process your payment..."
                    : error
                    ? "An error occurred while processing your payment."
                    : "Thank you for your payment. Your transaction has been completed successfully."}
                </p>
              </div>
              <div className="flex flex-col my-4">
                <Separator className="my-4" />
                <div className="divide-y divide-dashed divide-slate-400/25">
                  {Object.entries(invoiceDetails).map(([key, value]) => (
                    <div key={key} className="grid grid-cols-2 p-2">
                      <div className="text-muted-foreground">
                        <p>{key.charAt(0).toUpperCase() + key.slice(1)}</p>
                      </div>
                      <div className="text-end justify-self-end">
                        {isLoading ? <Skeleton className="h-4 w-24 ml-auto" /> : <p>{value}</p>}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center mt-4 space-y-4 sm:space-y-0 sm:space-x-4">
              <Button
                variant="outline"
                className="w-full sm:w-auto rounded-3xl"
                onClick={() => {
                  if (invoice?.invoicePdf) {
                    window.open(invoice.invoicePdf, "_blank");
                  }
                }}
                disabled={isLoading || !invoice?.invoicePdf}
              >
                <Download className="mr-2" />
                Get PDF Receipt
              </Button>
              <Button
                className="w-full sm:w-auto rounded-3xl"
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Go to your dashboard
              </Button>
            </div>
            {error && (
              <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded" role="alert">
                <p>{error}</p>
                <Button onClick={handleRetry} className="mt-2 bg-red-500 hover:bg-red-600 text-white">
                  Retry
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </LayoutPayment>
  );
}
