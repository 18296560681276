import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

interface LoadingIndicatorProps {
  message?: string;
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = () => {
  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <Card className="lg:col-span-2">
          <CardHeader>
            <CardTitle>
              <Skeleton className="h-6 w-1/3" />
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8 space-y-4 sm:space-y-0">
              <Skeleton className="h-4 w-1/2" />
              <div className="flex items-center space-x-2">
                <Skeleton className="h-8 w-16" />
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full min-w-[600px]">
                <thead>
                  <tr className="text-xs sm:text-sm font-medium text-muted-foreground border-b text-center">
                    <th className="w-1/4 pb-2 text-left">
                      <Skeleton className="h-4 w-1/2" />
                    </th>
                    <th className="w-1/4 pb-2 text-left">
                      <Skeleton className="h-4 w-1/2" />
                    </th>
                    <th className="w-1/4 pb-2">
                      <Skeleton className="h-4 w-1/2 mx-auto" />
                    </th>
                    <th className="w-1/4 pb-2">
                      <Skeleton className="h-4 w-1/2 mx-auto" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3, 4, 5].map((row) => (
                    <tr key={row} className="border-b last:border-b-0">
                      <td className="py-4 text-left">
                        <Skeleton className="h-4 w-3/4" />
                      </td>
                      <td className="py-4">
                        <Skeleton className="h-4 w-3/4 mx-auto" />
                      </td>
                      <td className="py-4 text-center">
                        <Skeleton className="h-4 w-1/2 mx-auto" />
                      </td>
                      <td className="py-4 text-center">
                        <Skeleton className="h-8 w-20 mx-auto" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-6 flex justify-center items-center space-x-1">
              <Skeleton className="h-8 w-16" />
              <Skeleton className="h-8 w-16" />
              <Skeleton className="h-8 w-16" />
              <Skeleton className="h-8 w-16" />
            </div>
          </CardContent>
        </Card>
        <div className="space-y-8 lg:space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>
                <Skeleton className="h-6 w-1/2" />
              </CardTitle>
            </CardHeader>
            <CardContent>
              <Skeleton className="h-8 w-3/4 mb-2" />
              <Skeleton className="h-4 w-1/2 mb-6" />
              <Skeleton className="h-10 w-full" />
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>
                <Skeleton className="h-6 w-1/2" />
              </CardTitle>
            </CardHeader>
            <CardContent>
              <Skeleton className="h-4 w-3/4 mb-4" />
              <div className="flex items-center justify-between p-3 sm:p-4 border rounded-lg bg-muted/30">
                <div className="flex items-center gap-2 sm:gap-4">
                  <Skeleton className="w-10 h-6 sm:w-12 sm:h-8 rounded" />
                  <Skeleton className="h-4 w-1/2" />
                </div>
                <Skeleton className="h-8 w-16" />
              </div>
              <Skeleton className="h-10 w-full mt-4" />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
